import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSkriboPurple]'
})
export class SkriboPurpleDirective {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(108, 90, 159)';
   }
}
