import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./sites/main/main.component";
import { ServiceComponent } from "./sites/service/service.component";
import { DhlComponent } from "./product_pages/dhl/dhl.component";
import { KuenstlerbedarfComponent } from "./product_pages/kuenstlerbedarf/kuenstlerbedarf.component";
import { GeschenkeComponent } from "./product_pages/geschenke/geschenke.component";
import { ProductsComponent } from "./components/products/products.component";
import { ImpressumComponent } from "./sites/impressum/impressum.component";
import { AngebotComponent } from "./sites/angebot/angebot.component";
import { SchuleComponent } from "./product_pages/schule/schule.component";
import { GrusskartenComponent } from "./product_pages/grusskarten/grusskarten.component";
import { PapierComponent } from "./product_pages/papier/papier.component";
import { BastelnComponent } from "./product_pages/basteln/basteln.component";
import { SchreibenComponent } from "./product_pages/schreiben/schreiben.component";
import { TrendComponent } from "./product_pages/trend/trend.component";
import { PresseUndTabakComponent } from "./product_pages/presse-und-tabak/presse-und-tabak.component";
const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: MainComponent },
  { path: "service", component: ServiceComponent },
  { path: "paketshop", component: DhlComponent },
  { path: "malen", component: KuenstlerbedarfComponent },
  { path: "schenken", component: GeschenkeComponent },
  { path: "products", component: ProductsComponent },
  { path: "impressum", component: ImpressumComponent },
  { path: "unser-angebot", component: AngebotComponent },
  { path: "schule", component: SchuleComponent },
  { path: "grusskarten", component: GrusskartenComponent },
  { path: "papier", component: PapierComponent },
  { path: "basteln", component: BastelnComponent },
  { path: "schreiben", component: SchreibenComponent },
  { path: "trend", component: TrendComponent },
  { path: "tabakwaren", component: PresseUndTabakComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
