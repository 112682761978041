import { Component, OnInit, Input } from "@angular/core";
import { Product } from "../../interfaces/product";
import { Observable, of } from "rxjs";
import { ContentService } from "src/app/services/content.service";
import { map } from "rxjs/operators";

@Component({
  selector: "products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"]
})
export class ProductsComponent implements OnInit {
  products: Observable<Product[]>;
  leftSide: Observable<Product[]>;
  middle: Observable<Product[]>;
  rightSide: Observable<Product[]>;
  constructor(private contentService: ContentService) {}

  @Input()
  productsFileName: string;

  ngOnInit() {
    this.contentService
      .getProducts(this.productsFileName)
      .pipe(
        map((products: Product[]) => {
          let division = Math.floor(products.length / this.getArrayDivision());
          this.leftSide = of(products.splice(0, division));
          this.middle = of(products.splice(0, division));
          this.rightSide = of(products.splice(0, products.length));
        })
      )
      .subscribe();
  }

  getArrayDivision(): number {
    return window.screen.width < 1200 ? 2 : 3;
  }

  smallerThanArrayDivision(index: number, length: number): boolean {
    return index < length / this.getArrayDivision();
  }
}

// <ng-container
//         *ngFor="let product of (products | async); let i = index; let c = count; (i>=/getArrayDivision())"
//       >
