import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angebot',
  templateUrl: './angebot.component.html',
  styleUrls: ['./angebot.component.scss']
})
export class AngebotComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
