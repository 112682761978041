import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-kuenstlerbedarf",
  templateUrl: "./kuenstlerbedarf.component.html",
  styleUrls: ["./kuenstlerbedarf.component.scss"]
})
export class KuenstlerbedarfComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
