import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSkriboGreen]'
})
export class SkriboGreenDirective {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(42, 131, 68)';
   }
}
