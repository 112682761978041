import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basteln',
  templateUrl: './basteln.component.html',
  styleUrls: ['./basteln.component.scss']
})
export class BastelnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
