import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { WhereToFindComponent } from "./components/where-to-find/where-to-find.component";
import { ProductsComponent } from "./components/products/products.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatToolbarModule,
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatSidenavModule,
  MatIconModule
} from "@angular/material";
import { ImgWrapperComponent } from "./components/imgWrapper/img-wrapper.component";
import { SkriboOrangeDirective } from "./directives/skribo-orange.directive";
import { SkriboGreenDirective } from "./directives/skribo-green.directive";
import { SkriboBlueDirective } from "./directives/skribo-blue.directive";
import { SkriboLightOrangeDirective } from "./directives/skribo-light-orange.directive";
import { SkriboRedDirective } from "./directives/skribo-red.directive";
import { SkriboPurpleDirective } from "./directives/skribo-purple.directive";
import { SkriboRandomColorDirective } from "./directives/skribo-random-color.directive";
import { TextCardComponent } from "./components/text-card/text-card.component";
import { CardimageleftComponent } from "./components/cardimageleft/cardimageleft.component";
import { CardimagerightComponent } from "./components/cardimageright/cardimageright.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FlipModule } from "ngx-flip";
import { HttpClientModule } from "@angular/common/http";
import { FooterComponent } from "./components/footer/footer.component";
import { SocialMediaComponent } from "./components/social-media/social-media.component";
import { ScrollTopButtonComponent } from "./components/scroll-top-button/scroll-top-button.component";
import { ServiceComponent } from "./sites/service/service.component";
import { KuenstlerbedarfComponent } from "./product_pages/kuenstlerbedarf/kuenstlerbedarf.component";
import { GeschenkeComponent } from "./product_pages/geschenke/geschenke.component";
import { DhlComponent } from "./product_pages/dhl/dhl.component";
import { ImpressumComponent } from "./sites/impressum/impressum.component";
import { AngebotComponent } from "./sites/angebot/angebot.component";
import { MainComponent } from "./sites/main/main.component";
import { TrendComponent } from "./product_pages/trend/trend.component";
import { SchuleComponent } from "./product_pages/schule/schule.component";
import { GrusskartenComponent } from './product_pages/grusskarten/grusskarten.component';
import { PapierComponent } from './product_pages/papier/papier.component';
import { BastelnComponent } from './product_pages/basteln/basteln.component';
import { SchreibenComponent } from './product_pages/schreiben/schreiben.component';
import { PresseUndTabakComponent } from './product_pages/presse-und-tabak/presse-und-tabak.component';
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NavBarComponent,
    WhereToFindComponent,
    ProductsComponent,
    ImgWrapperComponent,
    SkriboOrangeDirective,
    SkriboGreenDirective,
    SkriboBlueDirective,
    SkriboLightOrangeDirective,
    SkriboRedDirective,
    SkriboPurpleDirective,
    SkriboRandomColorDirective,
    TextCardComponent,
    CardimageleftComponent,
    CardimagerightComponent,
    ServiceComponent,
    KuenstlerbedarfComponent,
    GeschenkeComponent,
    FooterComponent,
    DhlComponent,
    SocialMediaComponent,
    ScrollTopButtonComponent,
    ImpressumComponent,
    AngebotComponent,
    TrendComponent,
    SchuleComponent,
    GrusskartenComponent,
    PapierComponent,
    BastelnComponent,
    SchreibenComponent,
    PresseUndTabakComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    FlipModule
  ],
  exports: [MatButtonModule, MatToolbarModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
