import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[skriboOrange]'
})
export class SkriboOrangeDirective implements Directive {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(232, 177, 51)';
   }

}
