import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSkriboRed]'
})
export class SkriboRedDirective {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(194, 0 , 22)';
   }
}
