import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Header } from "src/app/interfaces/header";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"]
})
export class ServiceComponent implements OnInit {
  constructor() {}
  flip: boolean = false;

  header$: Observable<Header>;

  ngOnInit() {}

  doFlip(): void {
    this.flip = !this.flip;
  }
}
