import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardimageleft',
  templateUrl: './cardimageleft.component.html',
  styleUrls: ['./cardimageleft.component.sass']
})
export class CardimageleftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
