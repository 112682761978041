import { Component, OnInit, Input } from "@angular/core";
import { ContentService } from "src/app/services/content.service";

@Component({
  selector: "app-img-wrapper",
  templateUrl: "./img-wrapper.component.html",
  styleUrls: ["./img-wrapper.component.scss"]
})
export class ImgWrapperComponent implements OnInit {
  constructor(private headerService: ContentService) {}

  @Input("headerName")
  headerName: string;

  title: string;
  text: string;
  imgSrc: string;

  ngOnInit() {
    this.headerService.getHeader(this.headerName).subscribe(header => {
      this.title = header.title;
      this.text = header.text;
      this.imgSrc = header.imgSrc;

      window.document
        .getElementById("top")
        .setAttribute("style", `background-image: url('${this.imgSrc}')`);
    });
  }
}
