import { Component, OnInit, HostListener } from "@angular/core";

@Component({
  selector: "app-scroll-top-button",
  templateUrl: "./scroll-top-button.component.html",
  styleUrls: ["./scroll-top-button.component.scss"]
})
export class ScrollTopButtonComponent implements OnInit {
  constructor() {}

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById("toTop").style.display = "block";
    } else {
      document.getElementById("toTop").style.display = "none";
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 10);
      }
    })();
  }

  ngOnInit() {}
}
