import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presse-und-tabak',
  templateUrl: './presse-und-tabak.component.html',
  styleUrls: ['./presse-und-tabak.component.scss']
})
export class PresseUndTabakComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
