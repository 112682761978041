import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-papier',
  templateUrl: './papier.component.html',
  styleUrls: ['./papier.component.scss']
})
export class PapierComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
