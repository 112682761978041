import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[skriboRandomColor]'
})
export class SkriboRandomColorDirective {
  colors: String[] = [
    'rgba(46, 134, 200, 1)',
    'rgba(42, 131, 68, 1)',
    'rgba(238, 198, 102, 1)',
    'rgba(232, 177, 51, 1)',
    'rgba(194, 0 , 22, 1)',
    'rgb(108, 90, 159)'
  ]

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor= this.colors[Math.floor(Math.random() * this.colors.length)];  
  }
}
