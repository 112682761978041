import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[skriboLightOrange]'
})
export class SkriboLightOrangeDirective {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(238, 198, 102)';
   }
}
