import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSkriboBlue]'
})
export class SkriboBlueDirective {

  constructor(el : ElementRef) {
    el.nativeElement.style.backgroundColor = 'rgb(46, 134, 200)';
   }
}
