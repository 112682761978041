import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-social-media",
  templateUrl: "./social-media.component.html",
  styleUrls: ["./social-media.component.scss"]
})
export class SocialMediaComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  goFacebook() {
    window.open(
      "https://www.facebook.com/pages/category/Educational-Supply-Store/SKRIBO-Laim-544964772260149/",
      "_blank"
    );
  }

  goInstagram() {
    window.open("https://www.instagram.com/skribolaim/", "_blank");
  }
}
