import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-geschenke",
  templateUrl: "./geschenke.component.html",
  styleUrls: ["./geschenke.component.scss"]
})
export class GeschenkeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
