import { Injectable } from "@angular/core";
import { Header } from "../interfaces/header";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Product } from "../interfaces/product";

@Injectable({
  providedIn: "root"
})
export class ContentService {
  constructor(private _http: HttpClient) {}

  public textPath = "../../../assets/text/";

  public getHeader(name: string): Observable<Header> {
    return this._http.get<Header>(`${this.textPath}${name}.json`, {
      responseType: "json"
    });
  }

  public getProducts(name: string): Observable<Product[]> {
    return this._http.get<Product[]>(`${this.textPath}${name}.json`, {
      responseType: "json"
    });
  }
}
